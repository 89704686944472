
$white:    #fff !default;
$gray-50:  #080808 !default;
$gray-75:  #1A1A1A !default;
$gray-100: #1E1E1E !default;
$gray-200: #2C2C2C !default;
$gray-300: #393939 !default;
$gray-400: #494949 !default;
$gray-500: #5C5C5C !default;
$gray-600: #7C7C7C !default;
$gray-700: #A2A2A2 !default;
$gray-800: #C8C8C8 !default;
$gray-900: #EFEFEF !default;
$black:    #000 !default;

