

body.Dashboard.index{
	background-color: $gray-50;
	.globalnav{
		border-top-color: $gray-50;
	}
	main{
		text-align: center;
		.logo{
			padding-top: 7rem;
		}
	}
}

body.Users.login{
	.card.shadow{
		box-shadow: none !important;
		border: none !important;
		background-color: transparent;
	}
	p.py-3{
		padding-left: 1rem;
	}
	footer{
		height: 160px;
	}
}

/* Home */
.icon-sm,
.icon-md,
.icon-lg,
.icon-xl{
	width: auto;
}
.icon-sm{
	height: 24px;
}
.icon-md{
	height: 32px;
}
.icon-lg{
	height: 48px;
}
.icon-xl{
	height: 80px;
}


/* avatars genericos */

.avatar-inline{
	display: flex;
	align-items: flex-center;
	& > div{
	}
	& > .avatar-text{
		padding-left: 10px;
		line-height: 1.1;
	}
}

.avatar{
	width: auto;
	border-radius: 8px;
}
.avatar-xs{
	width: auto;
	border-radius: 4px;
	height: 16px;
}
.avatar-sm{
	width: auto;
	border-radius: 8px;
	height: 24px;
}
.avatar-md{
	width: auto;
	border-radius: 12px;
	height: 32px;
}
.avatar-lg{
	width: auto;
	border-radius: 21px;
	height: 42px;
}
.avatar-xl{
	width: auto;
	border-radius: 36px;
	height: 72px;
}

.avatar-group{
	display: flex;
	align-items: flex-end;
	.avatar:first-child,
	.avatar:last-child{
		display: inline-block;
	}
	.avatar:last-child{
		border: 2px solid $gray-75;
	}
}
.avatar-group-sm{
	@extend .avatar-group;
	.avatar:first-child{
		@extend .avatar-sm;
	}
	.avatar:last-child{
		margin-left: -5px;
		height: 14px;
	}
}
.avatar-group-md{
	@extend .avatar-group;
	.avatar:first-child{
		@extend .avatar-md;
	}
	.avatar:last-child{
		margin-left: -8px;
		height: 20px;
	}
}
.avatar-group-lg{
	@extend .avatar-group;
	.avatar:first-child{
		@extend .avatar-lg;
	}
	.avatar:last-child{
		margin-left: -12px;
		height: 24px;
		border-radius: 12px;
	}
}
.avatar-group-xl{
	@extend .avatar-group;
	.avatar:first-child{
		@extend .avatar-xl;
	}
	.avatar:last-child{
		margin-left: -20px;
		height: 32px;
		border-radius: 16px;
	}
}

/* Buttons customized */

.btn-rounded{
	border-radius: 24px;
	padding: 0.375rem 1.25rem;
}

/* Card customized */

.card{
	margin-bottom: 10px;
	.card-header{
		padding-bottom: 0;
		border-bottom-color: transparent;
		color: $gray-800;
		font-size: $h6-font-size;
		font-weight: 400;
		text-transform: uppercase;
	}
	.card-header + .nav{
		margin-top: 0.75rem;
	}
}

/* Metric: individual metrics on different sizes */

.metric,
.metric-lg,
.metric-xl,
.metric-xxl{
	.metric-value{
		color: $white;
	}
	.metric-label{
		text-transform: uppercase;
		color: $gray-800;
		font-weight: 400;
	}
}
.metric{
	.metric-value{
		font-size: $h3-font-size;
	}
	.metric-label{
		font-size: $h5-font-size;
	}
}
.metric-lg{
	.metric-value{
		font-size: $font-size-base * 1.6;
	}
	.metric-label{
		font-size: $h5-font-size;
	}
}
.metric-xl{
	.metric-value{
		font-size: $font-size-base * 2;
	}
	.metric-label{
		font-size: $h5-font-size;
	}
}
.metric-xxl{
	.metric-value{
		font-family: trade-gothic-next, tgn-soft-round, $font-family-sans-serif;
		font-size: $font-size-base * 2.8;
		font-weight: 300;
	}
	.metric-label{
		font-size: $h4-font-size;
	}
}

/* Metric group, Meter, Versus */

.metric-group{
	@extend .d-flex;
	@extend .justify-content-between;
	@extend .align-items-end;
}

.metric-group-meter,
.metric-group-versus-lg{
	.progress{
		margin-bottom: 6px;
		height: 6px;
		.progress-bar{
		}
	}
}

.metric-group-versus-lg{
	.metrics-values{
		@extend .d-flex;
		@extend .justify-content-between;
		@extend .align-items-center;
		margin-bottom: 4px;
	}
	.metrics-labels{
		@extend .d-flex;
		@extend .justify-content-between;
		.metric-label{
			margin-bottom: 0;
		}
	}
}


.metric-group-meter{
	margin-bottom: 20px;
	&:last-child{
		margin-bottom: 0;
	}
	.metric{
		@extend .d-flex;
		@extend .justify-content-between;
		@extend .align-items-end;
		margin-bottom: 4px;
		.metric-label{
			font-size: $font-size-base;
			text-transform: none;
			color: $white;
		}
		.metric-value{
			font-size: $font-size-base;
		}
	}
}

.metric-trend{
	color: $gray-500;
	.value{
		color: $success;
	}
}

/* charts */

.chart-legend{
	text-align: center;
	padding-top: 10px;
	font-size: $font-size-sm;
	.legend{
		display: inline-block;
		.color-ref{
			display: inline-block;
			width: 24px;
			height: 8px;
			border-radius: 4px;
			margin-right: 6px;
		}
		margin-right: 24px;
		&:last-child{
			margin-right: 0;
		}
	}
}


/* ------------------ */

.featured{
	text-align: center;
	padding-bottom: 3.5rem;
	h2{
		margin-bottom: 2px;
	}
	p{
		margin-bottom: 8px;
	}
}


.section-title-overlaped{
	margin-top: -54px;
}
.section-title,
.section-title-overlaped{
	margin-bottom: 20px;
	text-align: center;
	h3{
		padding: 20px 20px;
		display: inline-block;
		background-color: $gray-75;
	}
	&>div{
		padding: 20px 20px;
		display: inline-block;
		background-color: $gray-75;
	}
}
.section-subtitle{
	margin-bottom: 20px;
}


/* Nav */

.card{
	.nav{
		@extend .flex-column;
		border-top: 1px solid $gray-200;
		a{
			border-bottom: 1px solid $gray-200;
			&:last-child{
				border-bottom: none;
			}
			.avatar-sm{
				display: inline-block;
				margin-right: 6px;
			}
		}
	}
}



/* Grid */

table.table{
	th{
		font-size: $font-size-sm;
		a{
			color: $gray-600;
		}
	}
	td.actions:last-child{
		text-align: right;
		.btn-more{
			margin-top: -8px;
			margin-bottom: -6px;
			padding: 0 1rem;
			font-size: 1.35rem;
		}
	}
}