/*!
 * Evil Corp (https://evilcorp.com.ar/)
 * Copyright 2004-2020 EvilCorp S.A.
 */


/**
 * ----------------------------------------
 * animation slide-top-logo
 * ----------------------------------------
 */
.slide-top-logo {
	-webkit-animation: slide-top-logo 1.75s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top-logo 1.75s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation-delay: 0;
}
@-webkit-keyframes slide-top-logo {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 0;
    filter: blur(16px);
  }
  100% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    opacity: 1;
    filter: blur(0.01);
  }
}
@keyframes slide-top-logo {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 0;
    filter: blur(16px);
  }
  100% {
    -webkit-transform: translateY(-12px);
            transform: translateY(-12px);
    opacity: 1;
    filter: blur(0.01);
  }
}


.slide-top-tagline {
	-webkit-animation: slide-top-tagline 2.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top-tagline 2.25s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation-delay: 1s;
}
@-webkit-keyframes slide-top-tagline {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    opacity: 1;
  }
}
@keyframes slide-top-tagline {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px);
    opacity: 1;
  }
}
