/*!
 * Evil Corp (https://evilcorp.com.ar/)
 * Copyright 2004-2020 EvilCorp S.A.
 */


/* For sticky footer */
$footer-height: 240px;

html {
  position: relative;
  min-height: 100%;
}
body {
  margin-bottom: $footer-height;
}
nav.globalnav{
	position: fixed;
	z-index: 1000;
	bottom:0;
	left: 0;
	width: 100%;
	height: 86px;
}
main{
	z-index: 39;
	padding-top: 40px;
	padding-bottom: 20px;
	.container-fluid{
		max-width: 1200px;
	}
}
footer{
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: $footer-height;

}


/* typography & colors */

h5,h6{
	text-transform: uppercase;
}
h6{
	color: $gray-800;
	font-weight: 400;
}


@include media-breakpoint-up(lg) {

}



hr{
	border-top-color: $gray-300;
}

.quiet{
	@extend .text-muted;
}

.globalnav{
	padding-top: 5px;
	background-color: $gray-50;
	box-shadow: 0 0 2rem rgba(0, 0, 0, 0.4);
	.nav{
		@extend .justify-content-center;
		.nav-item{
			padding: 0 8px;
			.nav-link{
				font-size: 12px;
				.nav-icon{
					display: block;
					margin: 0 auto;
				}
			}
		}
	}
}


.page-header{
	padding: 0 20px;
	@extend .d-flex;
	@extend .flex-row;
	@extend .justify-content-between;
	@extend .align-items-center;
	height: 60px;
	border-bottom: 1px solid $gray-300;
	.titlebar{
		font-size: $h3-font-size;
	}
	.action-primary,
	.action-secondary{
		a{
			display: inline-block;
			padding: 4px 8px;
			font-size: 20px;
			line-height: 20px;
			.icon::before{
				font-size: 20px;
				line-height: 20px;
				width: auto;
			}
		}
		width: 50px;
	}
}

footer.footer{
	padding-top: 46px;
	font-size: 12px;
	a{
		color: $gray-600;
		text-decoration: underline;
	}
	.logo-evil-corp img{
		max-width: 120px;
		display: block;
		margin: 0 auto 10px;
	}
}
