/*

====================================

Para compilar observando por cambios 
ejecutar (desde raiz del proyecto):

  $ npm run dev

====================================

*/

@import "../../../node_modules/bootstrap/scss/functions";

// Import custom variables
@import "site-variables";
@import "bootstrap-variables";
@import "animations";

// Import Bootstrap for Sass
@import "../../../node_modules/bootstrap/scss/bootstrap";

// Our Evil Stylesheets
@import "layout";
@import "custom";

//Para la seccion documentación que viene en markdown
@import "docs";

// Other Components
@import "../../../node_modules/ionicons/dist/scss/ionicons";
